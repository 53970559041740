import React from 'react';

import Meta from 'components/meta/Meta';

import { Image } from 'components/image/Image';
import { Header } from 'components/kranavatn/header/Header';
import { Header as DownloadHeader, WideSection, Items, Item, Button } from 'components/kranavatn/downloads/Downloads';

export default () => {

  const metaDescription = 'Media Library for Kranavatn';

  return (
    <>
      <Meta
        title="Media Library"
        description={metaDescription}
        image="https://s3-eu-west-1.amazonaws.com/kranavatn/images/share-image.jpg"
        imageAlt={metaDescription}
      />

      <Header
        logoLink="https://inspiredbyiceland.com/"
        navLink="/"
        navLinkText="Back to Kranavatn"
      />

      <DownloadHeader
        heading="Kranavatn Media Library"
        text="Download the Kranavatn campaign assets"
        logo={<Image src={require('assets/images/kranavatn/logo.png')} />}
        hero={
          <Image
            src={require('assets/images/kranavatn/hero-download-sm.jpg')}
            src2x={require('assets/images/kranavatn/hero-download-lg.jpg')}
          />
        }
      />

      <>
        <WideSection
          title="Brand guide"
          button={<Button to="/kranavatn/IBI 90033 Kranavatn brand guide.pdf">Download</Button>}
        >
          <p>
            Please download the Kranavatn Brandguide for the correct usage of the Kranavatn digital
            assets.
          </p>
          <p>
            Here you will find information regarding colors, fonts, placements and general do’s and
            dont’s for the Kranavatn Brand.
          </p>
        </WideSection>
        <Items>
          <Item
            image={<Image src={require('assets/images/kranavatn/logo.png')} />}
            title="Logo"
            button={<Button to="/kranavatn/IBI Logo download.ai">Download</Button>}
          >
            <p>Download an editable AI file of the Kranavatn logo.</p>
            <p>Please keep message the same, use only approved font.</p>
          </Item>
          <Item
            image={<Image src={require('assets/images/kranavatn/neck-hanger.png')} />}
            title="Neck hanger"
            button={<Button to="/kranavatn/IBI Neck hanger download.ai">Download</Button>}
          >
            <p>
              Perfect around taps of most shapes and sizes. For hotels, hostels or any establishment
              who want to display the message. Editable PSD.
            </p>
          </Item>
          <Item
            image={<Image src={require('assets/images/kranavatn/bottle.png')} />}
            title="Bottle"
            button={<Button to="/kranavatn/IBI Bottle download.psd">Download</Button>}
          >
            <p>Download a mockup for manufacturing Kranavatns bottles. Editable PSD.</p>
          </Item>
        </Items>
        <WideSection title="Fonts">
          <p>Please license the fonts for accurate usage of the material:</p>
          <ul>
            <li>Akzidenz-Grotesk Regular Condensed</li>
            <li>Garamond</li>
          </ul>
        </WideSection>
      </>
    </>
  );
};
