import React from 'react';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';

import s from './Downloads.scss';

interface IHeaderProps {
  heading: string;
  text: string;
  logo: React.ReactNode;
  hero: React.ReactNode;
}

interface IButtonProps {
  to: string;
  children: React.ReactNode;
}

interface IDownloadsProps {
  children: React.ReactNode;
}

interface IWideSectionProps {
  title: string;
  button?: React.ReactNode;
  children: React.ReactNode;
}

interface IItemsProps {
  children: React.ReactNode;
}

interface IItemProps {
  image: React.ReactNode;
  title: string;
  children: React.ReactNode;
  button: React.ReactNode;
}

export const Header = ({ heading, text, logo, hero }: IHeaderProps) => (
  <div className={s.header}>
    <div className={s.header__container}>
      <div className={s.header__row}>
        <div className={s.header__content}>
          <h2 className={s.header__heading}>{heading}</h2>
          <div className={s.header__text}>{text}</div>
        </div>
        <div className={s.header__logoWrapper}>
          <div className={s.header__logo}>{logo}</div>
        </div>
      </div>
    </div>
    <div className={s.header__container}>
      <div className={s.header__row}>
        <div className={s.header__hero}>{hero}</div>
      </div>
    </div>
  </div>
);

const downloadSvg = (
  <svg width="18" height="17" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd">
      <path d="M9 0v12" />
      <path stroke-linecap="square" d="M13.5 7.5L9 12 4.5 7.5M17 16H1" />
    </g>
  </svg>
);

export const Button = ({ to, children }: IButtonProps) => (
  <a href={to} className={s.download}>
    <span className={s.download__text}>{children}</span>
    <span className={s.download__icon}>{downloadSvg}</span>
  </a>
);

export const WideSection = ({ title, button, children }: IWideSectionProps) => (
  <div className={s.section}>
    <div className={s.section__container}>
      <div className={s.section__row}>
        <div className={s.section__content}>
          <h3 className={s.section__heading}>{title}</h3>
          {button && (
            <div className={s.section__button}>{button}</div>
          )}
        </div>
        <div className={s.section__text}>{children}</div>
      </div>
    </div>
  </div>
);

export const Items = ({ children }: IItemsProps) => (
  <div className={s.items}>
    <div className={s.items__container}>
      <div className={s.items__row}>
        {React.Children.map(children, (item) => (
          <div className={s.items__col}>{item}</div>
        ))}
      </div>
    </div>
  </div>
);

export const Item = ({ image, title, children, button }: IItemProps) => (
  <div className={s.item}>
    <div className={s.item__image}>{image}</div>
    <h3 className={s.item__heading}>{title}</h3>
    <div className={s.item__text}>{children}</div>
    <div className={s.item__button}>{button}</div>
  </div>
);
